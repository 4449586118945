import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import moment from "moment";
import "@babel/polyfill";
import "mutationobserver-shim";
import "./plugins/bootstrap-vue";
import "element-ui/lib/theme-chalk/index.css";
import "vue-lazy-youtube-video/dist/style.css";
import VueSocialSharing from "vue-social-sharing";
import "vue-share-ssr/dist/share.css";

Vue.use(VueSocialSharing);
Vue.config.productionTip = false;
Vue.use(ElementUI);

const url = window.location.hostname === 'localhost' ? 'http://localhost:3040/api/' : 'https://api.ressoarte.sistemaweb.com.br/api/'
axios.defaults.baseURL = url

Vue.prototype.$axios = axios;

moment.locale("pt-br");
Vue.prototype.$moment = moment;
Vue.filter("formatDate", (value, format) => {
  return moment(value).format(format);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
