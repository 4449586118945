<template>
  <div class="instituto" v-loading="loading">
    <h2>INSTITUTO</h2>
    <div class="instituto-data">
      <h5 class="instituto-data__title">{{ instituto.titulo }}</h5>
      <span class="instituto-data__desc" v-html="instituto.descricao"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "instituto",
  data() {
    return {
      instituto: {},
      loading: false,
    };
  },
  methods: {
    async getInstituto() {
      this.loading = true;
      const res = await this.$axios.get(`paginas/1`).catch((e) => {
        console.error("Etapas", e);
      });
      if (res.status === 200) {
        this.instituto = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getInstituto();
  },
};
</script>

<style lang="scss">
.instituto {
    padding: 20px;

  // margin: 20px;
  font-family: "Roboto", sans-serif;
  font-size: calc(var(--font-size--default) * 1.2);
  &-data {
    margin: 20px;
    &__title {
      background: var(--background-color-light);
      padding: 20px 0;
      font-weight: bold;
    }
    &__desc {
      text-align: justify;
      padding: 10px 20px;
      img {
        width: 60%
      }
    }
  }

  @media only screen and (max-width: 425px) {
    &-data{
      &__desc {
        img {
          width: 100%
        }
      }
    }
  }
}
</style>
