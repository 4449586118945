<template>
  <div class="destaque" v-loading="loading">
    <h2>PRINCIPAIS NOTÍCIAS</h2>
    <div class="destaque-body">
      <!-- <router-link to="/noticias" class="wrapper"> -->
      <div class="card-wrap">
        <CarouselNoticias
          :items="destaque"
          @handleShowDestaque="handleShowDestaque"
        />
        <!-- <Card :destaque="toShow" /> -->
      </div>
      <!-- </router-link> -->
      <div class="list">
        <Lista :destaque="destaque" @showId="showId" />
        <!-- <router-link to="/noticias" class="text-right">
          <small class="all">Todas...</small>
        </router-link> -->
        <div class="see-all">
          <a href="/noticias" class="text-right"
            ><span class="all-links">Todas</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselNoticias from "@/components/CarouselNoticias";
//import Card from "@/components/destaque/Card";
import Lista from "@/components/destaque/Lista";
export default {
  name: "Destaque",
  components: {
    //   Card,
    Lista,
    CarouselNoticias,
  },
  props: ["showDestaque"],
  data() {
    return {
      destaque: [],
      toShow: {},
      loading: false,
    };
  },
  methods: {
    slugify(text) {
      return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
    },
    getPage(id, title) {
      this.$router.push(`${`/noticia/${id}/${title}`}`);
    },
    handleShowDestaque(item) {
      this.getPage(item.id, this.slugify(item.titulo));
    },
    async getDestaques() {
      this.loading = true;
      const res = await this.$axios.get("noticias?limit=3").catch((e) => {
        console.error("Destaque", e);
      });
      if (res.status === 200) {
        this.destaque = res.data;
        this.toShow = res.data[0];
      }
      this.loading = false;
    },
    showId(id) {
      this.toShow = this.destaque.filter((item) => item.id === id)[0];
      this.handleShowDestaque(this.toShow);
    },
  },
  mounted() {
    this.getDestaques();
  },
};
</script>

<style lang="scss">
.destaque {
  text-align: left;
  margin: 20px 0;
  padding: 0 20px;

  h2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(var(--font-size--default) * 2);
    // font-size: 28.2233px;
    line-height: 42px;
    color: var(--color-text-title);
    text-align: left;
    padding-left: 20px;
  }
  &-body {
    display: flex;
    flex-direction: row-reverse;
    .card-wrap,
    .wrapper {
      width: 100%;
      .card {
        cursor: pointer;
        /* float: left; */
        // padding-right: 15px;
        border: none;
      }
    }
    .list {
      width: 40%;
      display: flex;
      flex-direction: column;
      background-color: var(--background-color-light);
      height: 100%;
      padding-bottom: 15px;

      .see-all {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px 0 0;
      }
      .text-right {
        width: 112px;
        height: 30px;
        background-color: var(--color-text-title);
        color: var(--background-color-primary);
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
        &:active,
        &:hover {
          background-color: var(--background-color-hover-buttons) !important;
          text-decoration: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .destaque {
    // margin: 20px;

    h2 {
      //   font-size: 22px;
      font-size: calc(var(--font-size--small) * 2);
    }

    &-body {
      display: flex;
      width: 100%;
      // flex-direction: column;

      .card-wrap,
      .wrapper {
        padding: 0;
      }
      .list {
        width: 40%;
      }
    }
  }
}
@media only screen and (max-width: 936px) {
  .destaque {
    h2 {
      // font-size: 18px;
      font-size: calc(var(--font-size--small) * 2);
    }

    &-body {
      display: flex;
      flex-direction: column;

      .card-wrap,
      .wrapper {
        padding: 0;

        .card {
          width: 100%;
          padding: 0;
          margin-bottom: 20px;
        }
      }

      .list {
        width: 100%;
      }
    }
  }
}
</style>
