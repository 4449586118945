<template>
  <div class="error">
    <h1>Ops! Página não encontrada :(</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.error {
  padding: 50px;
}
</style>
