<template>
  <div id="app">
    <router-view key="$route.path" />
    <back-to-top bottom="5px" right="0" visibleoffset="200">
      <i class="fa fa-chevron-up"></i>
    </back-to-top>
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import BackToTop from "vue-backtotop";

export default {
  name: "app",
  components: {
    BackToTop,
  },
};
</script>

<style lang="scss">
:root {
  --background-color-primary: #ffffff;
  --background-color-secondary: #a3228e;
  --background-color-footer: #ffffff;
  --background-color-menu: #a3228e;
  --background-color-semitransparent: rgba(163, 34, 142, 0.69);
  --background-color-hover: #410d38;
  --background-color-light: #dcdfe6;
  --background-gray-light: #e7e8eb;
  --background-color-yellow: #fff2cd;
  --background-color-light-green: #dff1ed;
  --background-color-hover-buttons: #a3228e;
  --color-buttons-links-blue: #60c3ef;
  --color-buttons-links-green: #60efc4;
  --color-buttons-green: #33954f;
  --color-link-active: #e3e3fd;
  --background-footer: #172242;
  --color-shadow: #fafafa;
  --accent-color: #cacaca;
  --text-primary-color: #222;
  --color-text-news: #000000;
  --color-news-title: #fafafa;
  --color-text-title: #3d0c35;
  --color-text-ress: #a3228e;
  --color-card-tabs-title: #87a8cc;
  --color-text-title-secondary: #1c1c1b;
  --color-text-tabs: #000000;
  --color-text-destaques: #494949;
  --color-border-right: #ee1450;
  --background-circle: #fcb900;
  --element-size: 4rem;
}

.larger-font > #app {
  font-size: 14px !important; /* (62.5/100) * 16px = 10px */
  --font-size--small: 14px; /* 14px */
  --font-size--default: 16px; /* 16px */
  --font-size--large: 18px; /* 24px */
  --font-size--extra-large: 83px; /* 24px */
}

.smaller-font > #app {
  font-size: 11px !important; /* (62.5/100) * 16px = 10px */
  --font-size--small: 10px; /* 14px */
  --font-size--default: 12px; /* 16px */
  --font-size--large: 14px; /* 24px */
  --font-size--extra-large: 81px; /* 24px */
}

#app {
  font-size: 12px !important;
  --font-size--small: 12px; /* 14px */
  --font-size--default: 14px; /* 16px */
  --font-size--large: 16px; /* 24px */
  --font-size--extra-large: 83px; /* 24px */
}

h2 {
  font-size: calc(var(--font-size--default) * 1.68) !important;
}

.vue-back-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
  background-color: var(--background-color-secondary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 3rem !important;
  box-shadow: 0px -2px 10px 0px var(--color-shadow);
}
</style>
