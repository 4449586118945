<template>
  <div id="appL">
    <Header />
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="info"
      :sticky="true"
      id="navbar"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <b-nav-item to="/">PRINCIPAL</b-nav-item> -->
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/instituto">O Instituto </b-nav-item>
          <b-nav-item to="/governanca-e-gestao"
            >Governança e Gestão</b-nav-item
          >
          <b-nav-item to="/linha-do-tempo">Linha do Tempo</b-nav-item>
          <b-nav-item to="/noticias">Notícias</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
    <CustomFooter />
  </div>
</template>

<script>
import Header from "@/components/Header";
import CustomFooter from "@/components/CustomFooter";
export default {
  components: {
    Header,
    CustomFooter,
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700;900&display=swap"); */

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../assets/Fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../assets/Fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../assets/Fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
}
</style>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
#appL {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;

  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 0 3px 2px var(--color-shadow);

  #navbar {
    background-color: var(--background-color-secondary) !important;
    // width: 1440px;
    display: flex;
    padding: 0 30px;
    min-height: 58.5px;

    li {
      border-right: 1px solid var(--color-border-right);
      height: 4.4em;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 0 10px;
      &:last-child {
        border: none;
      }
    }
    a {
      padding: 0 10px;
      font-weight: bold;
      color: var(--background-color-primary);
      font-size: calc(var(--font-size--large) * 1.2);
      font-size: 19px;
      font-family: "Roboto", sans-serif;
      // border-right: 1px solid #2c3e50;

      .menu-title {
        // font-size: calc(var(--font-size--small) * 1.2);
        font-size: 14.4px;
      }

      &.router-link-exact-active {
        color: #e3e3fd;
      }

      &.nav-link:hover,
      &.nav-link:focus {
        background-color: var(--background-color-hover);
        border-radius: 5px;
      }
    }
    .dropdown-menu {
      background-color: var(--background-color-menu) !important;
      width: 280px;
      li {
        border-right: none;
      }
      a {
        padding: 8px;
        .menu-title {
          margin: 0;
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.router-link-exact-active {
          color: #e3e3fd;
        }
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: var(--background-color-hover);
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  #appL {
    max-width: 1024px;

    #navbar {

      background-color: var(--background-color-secondary) !important;
      display: flex;
      padding: 0 20px;
      min-height: 48.5px;

      a {
        padding: 0 5px;
        font-size: 16px;
        // font-size: var(--font-size--large);
        // background-color: #334995 !important;
      }
    }
  }
}
@media only screen and (max-width: 990px) {
  #appL {
    #navbar {
      li,
      a {
        padding: 5px;
        border-right: none;
      }
      .dropdown-menu {
        width: auto;
        margin-top: 0;
      }
    }
  }
}
</style>
