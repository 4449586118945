<template>
  <div class="mmidias-destaque" v-loading="loading">
    <div class="mmidias-destaque__img0" v-if="imageZero.length > 0">
      <h5 class="mmidias-destaque__img0__title">{{ imageZero[0].titulo }}</h5>
      <a :href="imageZero[0].arquivo" target="_blank">
        <img :src="imageZero[0].imagem" alt="" />
      </a>
    </div>
    <div class="mmidias-destaque__body">
      <ul class="mmidias-destaque__body__list">
        <li
          v-for="(mmidia, index) in mmidiasList"
          :key="index"
          class="mmidias-destaque__body__item"
        >
        <a :href="mmidia.arquivo || '/'" target="_blank">
          <div class="imgmidia">
            <!-- {{mmidias.tipo}} -->
            <img :src="mmidia.imagem" alt="" />
          </div>
          <div class="mmidias-destaque__right">
            
              <h6 class="mmidias-destaque__body__item--title">
                {{ mmidia.titulo }}
              </h6>
            

            <p class="mmidias-destaque__body__item--desc">
              {{ mmidia.data }}
            </p>
          </div>
          </a>
        </li>
      </ul>
      <!-- <div class="see-all">
        <a href="/mmidias" class="text-right" v-if="mmidiasList.length"
          ><span class="all-links">Todas</span></a
        >
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "mmidias-destaque",
  components: {},
  data() {
    return {
      mmidiasList: [],
      loading: false,
      windowWidth: 0,
      imageZero: [],
    };
  },
  methods: {
    async getMmidias() {
      this.loading = true;
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`multimidias?offset=${offset}`)
        .catch((e) => {
          console.error("Mmidias Destaque", e);
        });
      if (res.status === 200) {
        this.mmidiasList = res.data.data.filter(item => item.tipo != 1);
        this.imageZero = res.data.data.filter(item => item.tipo == 1);
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 6;
      if (pageSize >= 1290) {
        offset = 8;
      }
      return offset;
    },
  },
  mounted() {
    this.getMmidias();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.imgmidia {
  border-radius: 10px;
  text-align: center;
  padding-top: 10px;
  img {
    border-radius: 10px !important;
    width: 100%;
    height: auto;
  }
}
.mmidias-destaque {
  margin: 20px 0 0;
  padding: 0 20px 40px;
  
  h2 {
    padding: 40px 20px 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(var(--font-size--default) * 2);
    color: var(--color-text-title);
    text-align: left;
  }

  &__img0 {
    &__title {
      // background: var(--background-color-light);
      padding: 20px 0;
      text-decoration: underline;
      text-decoration-color: var(--background-color-hover);
      text-align: left;
    }
    & img {
      width: 75%;
      height: auto;
    }
  }

  &__body {
    position: relative;
    margin-top: 20px;
    padding: 20px;
    background-color: var(--background-gray-light);
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      padding-left: 0;
      margin: 20px 0 0 0;
      list-style: none;
    }

    &__item {
      // width: 300px;
      width: 280px;
      // margin-top: 20px;
      text-align: left;
      display: flex;
      justify-content: center;
      // align-items: center;
      padding: 0 20px;
      border-radius: 10px;
      background-color: var(--background-color-primary);

      &--title {
        margin: 10px 0;
        color: var(--color-text-ress);
      }
      &--desc {
        color: var(--color-text-title);
      }
    }
  }

  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
  }
  .text-right {
    width: 112px;
    height: 30px;
    background-color: var(--color-text-title);
    color: #ffffff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: #4d66bf !important;
      text-decoration: none;
    }
  }
}
</style>
