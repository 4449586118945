<template>
  <b-container class="legal-notes">
    <h2>Notas Legais Cookies</h2>
    <p>Aqui irão as informações sobre cookies :)</p>
  </b-container>
</template>

<script>
export default {
  name: "legal-notes",
};
</script>

<style lang="scss">
.legal-notes {
  padding: 40px;
}
</style>
