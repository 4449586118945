<template>
  <b-container class="videos" v-loading="loading">
    <h2 class="videos-h2">MULTIMÍDIAS</h2>
    <div class="videos-body">
      <ul class="videos-body__list">
        <li
          v-for="(video, index) in videosList.data"
          :key="index"
          class="videos-body__item"
        >
          <LazyYoutubeVideo
            class="imgvideo"
            :src="video.url"
            preview-image-size="hqdefault"
            :aspect-ratio="video.aspectRatio"
          />
          <h6 class="videos-body__item--title">{{ video.titulo }}</h6>
          <span class="videos-body__item--desc">{{ video.descricao }}</span>
        </li>
      </ul>
    </div>
    <div v-if="Object.keys(videosList).length > 0">
      <b-pagination
        align="center"
        v-model="videosList.current_page"
        pills
        :total-rows="videosList.total"
        @change="handleChangePage"
        :per-page="videosList.per_page"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";
export default {
  name: "videos",
  components: {
    LazyYoutubeVideo,
  },
  data() {
    return {
      videosList: [],
      loading: false,
      windowWidth: 0,
    };
  },
  methods: {
    async getVideos(pg = 1) {
      this.loading = true;
      this.videosList = [];
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`videos?page=${pg}&offset=${offset}`)
        .catch((e) => {
          console.error("Videos", e);
        });
      if (res.status === 200) {
        this.videosList = res.data;
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 9;
      if (pageSize < 970) {
        offset = 10;
      } else if (pageSize >= 1290) {
        offset = 12;
      }
      return offset;
    },
    handleChangePage(pg) {
      this.getVideos(pg);
    },
  },
  mounted() {
    this.getVideos();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.videos {
  margin: 20px;
  &-h2 {
    color: var(--color-text-title);
  }
  &-body {
    margin-bottom: 40px;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      padding-left: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
      list-style: none;
    }

    &__item {
      width: 300px;
      margin-top: 20px;
      text-align: left;

      &--title {
        margin: 10px 0;
        color: var(--color-text-ress);
      }
      &--desc {
        color: var(--color-text-title);
      }
    }
  }
}
</style>
