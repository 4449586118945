<template>
  <b-container class="revistas" v-loading="loading">
    <h2 class="revistas-h2">REVISTAS</h2>
    <div class="revistas-body">
      <ul class="revistas-body__list">
        <li
          v-for="(revista, index) in revistasList.data"
          :key="index"
          class="revistas-body__item"
        >
          <div class="revistas__left">
            <img :src="revista.imagem" alt="" />
          </div>
          <div class="revistas__right">
            <a :href="revista.url || '/'" target="_blank">
              <h6 class="revistas-body__item--title">
                {{ revista.titulo }}
              </h6>
            </a>

            <p class="revistas-body__item--desc">
              {{ revista.descricao }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="Object.keys(revistasList).length > 0">
      <b-pagination
        align="center"
        v-model="revistasList.current_page"
        pills
        :total-rows="revistasList.total"
        @change="handleChangePage"
        :per-page="revistasList.per_page"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "revistas",
  data() {
    return {
      revistasList: [],
      loading: false,
      windowWidth: 0,
    };
  },
  methods: {
    async getRevistas(page = 1) {
      this.loading = true;
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`revistas?page=${page}&offset=${offset}`)
        .catch((e) => {
          console.error("revista Destaque", e);
        });
      if (res.status === 200) {
        this.revistasList = res.data;
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 6;
      if (pageSize >= 1290) {
        offset = 8;
      }
      return offset;
    },
    handleChangePage(pg) {
      this.getRevistas(pg);
    },
  },
  mounted() {
    this.getRevistas();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.revistas {
  padding: 40px;
  background: var(--background-color-light);

  &__left {
    text-align: center;
  }

  &__right {
    width: 85%;
  }

  a:hover {
    text-decoration: none;

    h6 {
      font-weight: bolder;
    }
  }

  &-body {
    position: relative;
    &__list {
      display: flex;
      gap: 20px;
      padding-left: 0;
      margin: 0 auto;
      list-style: none;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__item {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: auto;
        width: 80%;
      }

      &--title {
        margin: 15px 0 10px;
        color: var(--color-text-ress);
      }
      &--desc {
        padding: 0;
        color: var(--color-text-title);
        text-align: justify;
      }
    }
  }

  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
  }
  .text-right {
    width: 112px;
    height: 30px;
    background-color: var(--color-text-title);
    color: #ffffff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: var(--color-text-ress) !important;
      text-decoration: none;
    }
  }
}
</style>
