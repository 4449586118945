<template>
  <div class="lista" v-if="destaque.length > 0">
    <b-list-group
      v-for="item in destaque"
      :key="item.id"
      @click="handleClick(item.id)"
    >
      <b-list-group-item href="#" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between" v-if="item.data">
          <span class="lista-time">{{ item.data | formatDate("LL") }}</span>
        </div>
        <p class="mb-1 lista-title">
          {{ item.titulo }}
        </p>
        <div class="lista-border"></div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "lista",
  props: {
    "bg-color": {
      type: String,
      default: "#dfe7f1",
    },
    destaque: {
      type: Array,
      default: () => [],
    },
    showId: {
      type: Function,
      default: () => ({}),
    },
  },
  methods: {
    handleClick(e) {
      this.$emit("showId", e);
    },
  },
};
</script>

<style lang="scss">
.lista {
  height: 480px;
  overflow-y: auto;
  padding: 12px;
  background-color: var(--background-color-light);

  .list-group {
    border-radius: 0;
    &-item {
      padding: 10px;
    }

    &:last-child {
      .lista-border {
        display: none;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(130, 130, 130, 1);
    background-color: #f2f2f2;
    border-radius: 3px;
    border-radius: 0px 0px 9px 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 6px;
    border: 3px solid #cfd8dc;
  }

  .list-group-item {
    background-color: var(--background-color-light);
    border: none;
  }
  font-family: "Roboto", sans-serif;
  font-style: normal;
  &-time {
    font-weight: normal;
    // font-size: 10.6664px;
    font-size: var(--font-size--small);
    line-height: 16px;
    color: #8e8e8e;
  }
  &-title {
    font-weight: bold;
    // font-size: 19.9995px;
    font-size: calc(var(--font-size--small) * 2);
    line-height: 30px;
    color: var(--color-text-title);

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-border {
    margin-top: 20px;
    border-bottom: 2px solid var(--color-text-title);
  }
}

@media only screen and (max-width: 1224px) {
  .lista {
    height: 360px;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    &-time {
      font-weight: normal;
      // font-size: 8px;
      font-size: calc(var(--font-size--large) / 2);
      line-height: 12px;
      color: var(--color-card-tabs-title);
    }
    &-title {
      font-weight: bold;
      // font-size: 14px;
      font-size: var(--font-size--default);
      line-height: 21px;
      color: var(--color-text-destaques);
    }
    &-border {
      margin-top: 10px;
      border-bottom: 1px solid var(--color-text-title);
    }
  }
}

@media only screen and (max-width: 936px) {
  .lista {
    height: auto;
  }
}
</style>
