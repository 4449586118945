<template>
  <div class="home">
    <Destaque />
    <VideosDestaque />
    <Portfolio />
  </div>
</template>

<script>
import Destaque from "@/components/Destaque";
import VideosDestaque from "@/components/VideosDestaque";
import Portfolio from "@/components/Portfolio";

export default {
  name: "Home",
  components: {
    Destaque,
    VideosDestaque,
    Portfolio,
  }
};
</script>

<style lang="scss">
.home {
  max-width: 1440px;
  padding: 0;
  background-color: #ffffff;
}
</style>
