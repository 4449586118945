<template>
  <div class="videos-destaque" v-loading="loading">
    <h2>MULTIMÍDIAS</h2>
    <div class="videos-destaque__body">
      <ul class="videos-destaque__body__list">
        <li
          v-for="(video, index) in videosList"
          :key="index"
          class="videos-destaque__body__item"
        >
          <LazyYoutubeVideo
            class="imgvideo"
            :src="video.url_embed"
            preview-image-size="hqdefault"
            :aspect-ratio="video.aspectRatio"
          />
          <h6 class="videos-body__item--title">{{ video.titulo }}</h6>
          <span class="videos-body__item--desc">{{ video.descricao }}</span>
        </li>
      </ul>
      <div class="see-all">
        <a href="/videos" class="text-right" v-if="videosList.length"
          ><span class="all-links">Todas</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";
export default {
  name: "videos-destaque",
  components: {
    LazyYoutubeVideo,
  },
  data() {
    return {
      videosList: [],
      loading: false,
      windowWidth: 0,
    };
  },
  methods: {
    async getVideos() {
      this.loading = true;
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`videos?offset=${offset}`)
        .catch((e) => {
          console.error("Videos Destaque", e);
        });
      if (res.status === 200) {
        this.videosList = res.data.data;
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 3;
      if (pageSize >= 1290) {
        offset = 4;
      }
      return offset;
    },
  },
  mounted() {
    this.getVideos();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.imgvideo {
  border-radius: 10px;
  img {
    border-radius: 10px !important;
  }
}
.videos-destaque {
  margin: 20px 0;
  padding: 0 20px;
  h2 {
    padding: 40px 20px 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(var(--font-size--default) * 2);
    color: var(--color-text-title);
    text-align: left;
  }

  &__body {
    position: relative;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      padding-left: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
      list-style: none;
    }

    &__item {
      width: 300px;
      margin-top: 20px;
      text-align: left;

      &--title {
        margin: 10px 0;
        color: var(--color-text-ress);
      }
      &--desc {
        color: var(--color-text-title);
      }
    }
  }

  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
  }
  .text-right {
    width: 112px;
    height: 30px;
    background-color: var(--color-text-title);
    color: #ffffff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: #4d66bf !important;
      text-decoration: none;
    }
  }
}
</style>
