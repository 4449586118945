<template>
  <div class="gestoes" v-loading="loading">
    <h2>GOVERNANÇA E GESTÃO</h2>
    <div
      class="gestoes-data"
      v-for="(gestao, index) in gestoes.data"
      :key="index"
    >
      <h5 class="gestoes-data__title">{{ gestao.titulo }}</h5>
      <div class="gestoes-data__desc">{{ gestao.descricao }}</div>
    </div>
    <MultiMidias />
  </div>
</template>

<script>
import MultiMidias from "@/components/MultiMidias";
export default {
  name: "governanca-e-gestao",
  components: {
    MultiMidias,
  },
  data() {
    return {
      gestoes: [],
      loading: false,
    };
  },
  methods: {
    async getGestoes(page = 1) {
      this.loading = true;
      const res = await this.$axios.get(`gestoes?page=${page}`).catch((e) => {
        console.error("Etapas", e);
      });
      if (res.status === 200) {
        this.gestoes = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getGestoes();
  },
};
</script>

<style lang="scss">
.gestoes {
  padding: 40px 0;
  margin: 20px;
  font-family: "Roboto", sans-serif;
  font-size: var(--font-size--default);
  &-data {
    margin: 0 20px;
    &__title {
      // background: var(--background-color-light);
      padding: 20px 0;
      text-decoration: underline;
      text-decoration-color: var(--background-color-hover);
      text-align: left;
    }
    &__desc {
      text-align: justify;
      padding: 10px 20px;
    }
  }
}
</style>
