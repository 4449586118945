<template>
  <div class="timeline" v-loading="loading">
    <h2>LINHA DO TEMPO</h2>
    <div class="tml" v-for="(etapa, index) in etapas" :key="index">
      <div class="tml-data">
        <!-- <div class="tml-data__images">
        <img :src="etapa.imagem" alt="" />
      </div> -->
        <div class="circle">
          <h6>{{ etapa.titulo }}</h6>
        </div>
        <div class="tml-data__textos">
          <div class="descricao">{{ etapa.descricao }}</div>
        </div>
      </div>
      <div class="tml-data__images" v-if="etapa.image.length > 0">
        <div
          class="tml-data__images-inside"
          v-for="item in etapa.image"
          :key="item"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "linha-do-tempo",
  data() {
    return {
      etapas: [],
      loading: false,
    };
  },
  methods: {
    async getEtapas() {
      this.loading = true;
      const res = await this.$axios.get("etapas").catch((e) => {
        console.error("Etapas", e);
      });
      if (res.status === 200) {
        this.etapas = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getEtapas();
  },
};
</script>

<style lang="scss">
.timeline {
 
  padding: 20px;
  white-space: pre-line;

  padding: 40px;
  font-family: "Roboto", sans-serif;
  font-size: var(--font-size--default);
  background: var(--background-color-yellow);

  .tml-data {
    display: flex;
    align-items: center;
    margin: 20px;

    .circle {
      background: var(--background-circle);
      height: 100px;
      width: 100px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 100px;
      margin-left: 10px;
    }
    &__textos {
      display: flex;
      margin: 20px;
      padding: 20px;
      border-radius: 15px;
      width: 100%;
      background: var(--background-color-primary);
      text-align: left;
      h6 {
        font-weight: bold;
      }
      .descricao {
        text-align: justify;
      }
    }
    &__images {
      padding: 0;
      margin: 0 40px;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      // justify-content: center;
      &-inside {
        img {
          border-radius: 5px;
          min-width: 350px;
          max-width: 100%;
          height: 250px;
          object-fit: cover;
          // max-height: 225px;
          padding: 0 2px;
        }
      }
    }
  }

  @media only screen and (max-width: 602px) {
    padding: 20px;
    .tml-data {
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
      &__images {
        margin: 0;
      //   img {
      //     max-width: 300px;
      //     height: auto;
      //   }
      }
    }
  }
}
</style>
