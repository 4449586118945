<template>
  <div class="right-area">
    <div class="right-area__atend">
      <!-- <div class="superior">ATENDIMENTO</div>
      <div class="inferior">
        De segunda à sexta, <br />
        das 07h às 13h
      </div> -->
      <div class="right-area__redes">
        <!-- <div class="superior">REDES SOCIAIS</div> -->
        <div class="endereco">
            <div>Av. Manoel Murtinho, 2030, centro</div>
            <div>Anastácio/MS</div>
            <div>CEP 790210-000</div>
            <div>institutoressoarte@gmail.com</div>
            <div>Contato:  67 99951-5055 ou 67 98151-0365</div>
        </div>
        <div class="social-icons">
          <a href="https://www.facebook.com/ressoarte" target="_blank">
            <img src="@/assets/icon-facebook.png" alt="" />
          </a>
          <a
            href="https://www.instagram.com/instituto.ressoarte/"
            target="_blank"
          >
            <img src="@/assets/icon-instagram.png" alt="" />
          </a>
          <a
            href="https://youtube.com/channel/UCQNMQemegScNXg-IAcHUNgA"
            target="_blank"
          >
            <img src="@/assets/icon-youtube.png" alt="" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5567981510365"
            target="_blank"
          >
            <img src="@/assets/icon-whatsapp.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "right-area",
  methods: {
    getImgUrl(icone) {
      return require(`../../assets/images/${icone}`);
    },
  },
};
</script>

<style lang="scss">
.right-area {
  // &__atend,
  &__redes {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    // font-size: 16.8049px;
    font-size: var(--font-size--large);
    line-height: 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: left;
    .superior {
      font-weight: bold;
    }
    .inferior {
      font-weight: normal;
    }
  }
  &__redes {
    .icone {
      height: 24px;
      width: 123px;
      margin-top: 5px;
      cursor: pointer;
    }

    .endereco {
        color: var(--color-text-title);
    }

    .social-icons {
      display: block;
      margin: 10px 0;
      text-align: left;
      img {
        cursor: pointer;
        margin-right: 5px;
        height: 25px;
        width: 25px;
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .right-area {
    &__atend,
    &__redes {
      // font-size: 12px;
      font-size: var(--font-size--small);
      line-height: 16px;
    }
  }
}

// @media only screen and (max-width: 936px) {
//   .right-area {
//     display: none;
//   }
// }
</style>
