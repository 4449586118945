<template>
  <div class="portfolio" v-loading="loading">
    <h2>PORTIFÓLIO</h2>
    <div class="portfolio__body">
      <ul class="portfolio__body__list">
        <li
          v-for="(item, index) in portfolioList"
          :key="index"
          class="portfolio__body__item"
        >
          <img :src="item.img" alt="" />
          <h6 class="portfolio__body__item--title">{{ item.titulo }}</h6>
          <span v-html="item.descricao"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "portfolio",
  data() {
    return {
      portfolioList: [],
      loading: false,
    };
  },
  methods: {
    async getPortfolio() {
      this.loading = true;
      const res = await this.$axios.get(`portfolio`).catch((e) => {
        console.error("Portfolio", e);
      });
      if (res.status === 200) {
        this.portfolioList = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getPortfolio();
  },
};
</script>

<style lang="scss">
.portfolio {
  background-color: var(--background-color-yellow);
  text-align: left;
  margin-top: 0;
  padding: 0 20px;
  h2 {
    padding: 20px 0 0 20px;
    font-weight: bold;
    color: var(--color-text-title);
  }
  &__body {
    position: relative;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      padding-left: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
      list-style: none;
    }

    &__item {
      width: 300px;
      margin: 20px;
      text-align: left;
      img {
        border-radius: 5px;
        min-width: 300px;
        max-width: 100%;
        height: 200px;
        object-fit: cover;
      }

      &--title {
        margin: 10px 0;
        color: var(--color-text-ress);
      }
    }

    @media only screen and (max-width: 1024) {
      &__body {
        &__list{
          justify-content: space-evenly;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      &__item {
        width: 285px;
        margin: 20px;
        text-align: left;

        img {
          border-radius: 5px;
          min-width: 250px;
          max-width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>

<style></style>
