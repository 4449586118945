<template>
  <div class="header">
    <!-- Image and text -->
    <b-navbar variant="faded" type="light" :class="`barra ${chosenImage[randomNumber()]}`">
      <b-navbar-brand href="#">
        <img
          src="@/assets/images/logo-ress.png"
          class="logo d-inline-block align-top"
          alt="logo"
          @click="toPortal"
        />
      </b-navbar-brand>

      <div class="social-icons">
        <a href="https://www.facebook.com/ressoarte/" target="_blank">
          <img src="@/assets/icon-facebook.png" alt="" />
        </a>
        <a
          href="https://www.instagram.com/instituto.ressoarte/?igshid=YmMyMTA2M2Y%3D"
          target="_blank"
        >
          <img src="@/assets/icon-instagram.png" alt="" />
        </a>
        <a
          href="https://youtube.com/channel/UCQNMQemegScNXg-IAcHUNgA"
          target="_blank"
        >
          <img src="@/assets/icon-youtube.png" alt="" />
        </a>
        <!-- <a href="" target="_blank">
          <img src="@/assets/icon-twitter.png" alt="" />
        </a> -->
        <a
          href="https://api.whatsapp.com/send?phone=5567981510365"
          target="_blank"
        >
          <img src="@/assets/icon-whatsapp.png" alt="" />
        </a>
      </div>
      <!-- <div class="access-icons">
        <span @click="accessibleFontSize('add')">
          <img :src="require(`../assets/images/access01.png`)" alt="" />
        </span>
        <span @click="accessibleFontSize('rm')">
          <img :src="require(`../assets/images/access02.png`)" alt="" />
        </span>
        <span>
          <img :src="require(`../assets/images/access03.png`)" alt="" />
        </span>
        <span>
          <img :src="require(`../assets/images/access04.png`)" alt="" />
        </span>
        <span>
          <img :src="require(`../assets/images/access05.png`)" alt="" />
        </span>
      </div> -->
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapGetters(["loadingSearch"]),
  },
  data() {
    return {
      text: "",
      listaAccess: [
        "access01.png",
        "access02.png",
        "access03.png",
        "access04.png",
        "access05.png",
      ],
      lastSize: 0,
      lastValue: "",
      chosenImage: ["image-1", "image-2","image-3","image-4","image-5","image-6", "image-7", "image-8"],
    };
  },
  methods: {
    randomNumber() {
      return Math.floor(Math.random() * this.chosenImage.length);
    },
    handleEnter() {
      console.log("enter was pressed", this.text);
      if (this.text.length >= 3) {
        this.$router.push(`/search/${this.text}`);
      }
    },
    handleLinkMedia() {
      console.log("media was clicked", this.text);
    },
    toPortal() {
      this.$router.push("/");
    },
    accessibleFontSize(value) {
      if (this.lastValue === value && this.lastSize !== 0) {
        return;
      }
      this.lastValue = value;
      if (value === "add" && this.lastSize === 0) {
        this.lastSize = 1;
        document.body.classList.add("larger-font");
        document.body.classList.remove("smaller-font");
      } else if (value === "rm" && this.lastSize === 0) {
        this.lastSize = 2;
        document.body.classList.add("smaller-font");
        document.body.classList.remove("larger-font");
      } else {
        this.lastSize = 0;
        document.body.classList.remove("smaller-font");
        document.body.classList.remove("larger-font");
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: center;
  .navbar {
    background-color: var(--color-shadow);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .image-1 {
    background-image: url("../assets/images/instituto/bg_1.png");
  }
  .image-2 {
    background-image: url("../assets/images/instituto/bg_2.png");
  }
  .image-3 {
    background-image: url("../assets/images/instituto/bg_3.png");
  }
  .image-4 {
    background-image: url("../assets/images/instituto/bg_4.png");
  }
  .image-5 {
    background-image: url("../assets/images/instituto/bg_5.png");
  }
  .image-6 {
    background-image: url("../assets/images/instituto/bg_6.png");
  }
  .image-7 {
    background-image: url("../assets/images/instituto/bg_7.png");
  }
  .image-8 {
    background-image: url("../assets/images/instituto/bg_8.png");
  }
  .barra {
    height: 156px;
    width: 100%;
    /* width: 1440px; */
    max-width: 1440px !important;

    .logo {
      padding: 0 20px;
      height: 150px;
    }

    .input {
      max-width: 421px;
      height: 50px;
      background: url("../assets/images/search.svg") no-repeat right;
      background-size: 25px;
      background-position-x: 375px;
      background-color: #dfe7f1;
      border-radius: 10px;
      border: none;
      padding-right: 50px;
      color: #87a8cc !important;
    }
    .form-control {
      // font-size: 19px;
      font-size: calc(var(--font-size--default) * 1.3);
    }
    .social-icons {
      padding: 0 20px;
      img {
        cursor: pointer;
        margin: 0 3px;
        height: 40px;
        width: 40px;
      }
    }
    .access-icons {
      margin-left: 10px;
      img {
        cursor: pointer;
        margin: 0 2px;
        height: 50px;
      }
    }
  }
}
@media only screen and (max-width: 1023px) {
  /* Styles */
  .header {
    max-width: 1023px !important;
    .barra {
      height: 126px;
      /* width: 1440px; */
      .logo {
        // padding: 0px;
        height: 100px;
      }

      .input {
        max-width: 330px;
        height: 40px;
        background: url("../assets/images/search.svg") no-repeat right;
        background-size: 25px;
        background-position-x: 290px;
        background-color: #dfe7f1;
        border-radius: 10px;
        border: none;
        padding-right: 50px;
        color: #87a8cc !important;
      }
      .form-control {
        // font-size: 19px;
        font-size: var(--font-size--default);
      }
      .social-icons {
        // margin-left: 10px;
        img {
          cursor: pointer;
          margin: 0 3px;
          height: 30px;
          width: 30px;
        }
      }
      .access-icons {
        margin-left: 10px;
        img {
          cursor: pointer;
          margin: 0 2px;
          height: 35px;
        }
      }
    }
  }
}

@media only screen and (max-width: 936px) {
  .access-icons {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    .navbar {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
      background-position: left;
      // margin-top: 20px;

      .input {
        display: none;
      }
    }
    .social-icons {
      display: none;
    }
  }
}
</style>
