import Vue from "vue";
import VueRouter from "vue-router";
import Erro404 from "@/templates/404.vue";
import Galerias from "@/views/Galerias.vue";
import Governanca from "@/views/Governanca.vue";
import Home from "../views/Home.vue";
import Instituto from "@/views/Instituto.vue";
import LegalNotes from "@/views/LegalNotes.vue";
import Revistas from "@/views/Revistas.vue";
import SiteTemplate from "@/templates/SiteTemplate.vue";
import Timeline from "@/views/Timeline.vue";
import Videos from "@/views/Videos.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: SiteTemplate,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () => import("../views/News.vue"),
      },
      {
        path: "/noticia/:id/:title",
        name: "Noticia",
        component: () => import("../views/Noticias.vue"),
      },
      {
        path: "/galeria/:id/:title",
        name: "Galeria",
        component: () => import("../views/Galeria.vue"),
      },
      {
        path: "/legal-notes",
        name: "LegalNotes",
        component: LegalNotes,
      },
      {
        path: "/videos",
        name: "Videos",
        component: Videos,
      },
      {
        path: "/revistas",
        name: "Revistas",
        component: Revistas,
      },
      {
        path: "/instituto",
        name: "Instituto",
        component: Instituto,
      },
      {
        path: "/governanca-e-gestao",
        name: "Governanca",
        component: Governanca,
      },
      {
        path: "/linha-do-tempo",
        name: "Timeline",
        component: Timeline,
      },
      {
        path: "/galerias",
        name: "Galerias",
        component: Galerias,
      },
      {
        path: "*",
        name: "error404Site",
        component: Erro404,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  // ...
  next();
});

export default router;
