<template>
  <div class="footer-holder">
    <div class="custom-footer">
        <RightArea />
        <CenterArea />
      <LogoArea />
    </div>
  </div>
</template>

<script>
import LogoArea from "@/components/customFooter/LogoArea";
import CenterArea from "@/components/customFooter/CenterArea";
import RightArea from "@/components/customFooter/RightArea";
export default {
  name: "custom-footer",
  components: {
    LogoArea,
    CenterArea,
    RightArea,
  },
};
</script>

<style lang="scss">
.footer-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 3px 2px var(--color-shadow);
}
.custom-footer {
  display: flex;
  background-color: var(--background-gray-light);
  border-top: 3px solid var(--background-color-secondary);
  width: 1440px;
  align-items: center;
  justify-content: space-between;
  padding: 40px 60px;
  .to-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
  }
}

@media only screen and (max-width: 604px) {
  .custom-footer {
    padding: 20px 40px 20px 20px;
    display: flex;
    flex-direction: column;
    height: initial;
  }
}
</style>
